import React from 'react';
import cn from 'classnames';

interface SidebarProps {
  isOpen: boolean;
  children: React.ReactNode;
  setMenuOpen: (open: boolean) => void;
}

const Sidebar = ({ isOpen, children, setMenuOpen }: SidebarProps) => (
  <>
    {isOpen && (
      <div
        onClick={() => setMenuOpen(false)}
        className="fixed z-[1000] w-full h-screen bg-black bg-opacity-30 opacity-100 transition-opacity duration-300 top-0 left-0"
        role="button"
        aria-label="Close overlay"
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            setMenuOpen(false);
          }
        }}
      />
    )}
    <div className="h-full box-border overflow-auto">
      <nav
        className={cn(
          'fixed h-screen p-0 m-0 transition-transform duration-500 text-[#2c2c2c] top-0 w-[300px] -ml-3 bg-white z-[1100]',
          {
            'transform translate-x-0': isOpen,
            'transform -translate-x-full': !isOpen,
          },
        )}
        aria-hidden={!isOpen}
      >
        <ul className="h-full flex flex-col justify-between">{children}</ul>
      </nav>
    </div>
  </>
);

export default Sidebar;
