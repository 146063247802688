/* eslint-disable no-nested-ternary */
import * as React from 'react';
import UserContext from 'contexts/UserContextContainer/UserContext';
import AccountMenuPopover from '../AccountMenuPopover/AccountMenuPopover';

const AccountMenu = () => {
  const { currentUser } = React.useContext(UserContext);
  return (
    <div className="relative ml-4 flex-shrink-0">
      <AccountMenuPopover
        chevronClassName="ml-1"
        buttonContent={
          <span data-cy="accountManagementNavBtn" className="paragraph-medium-mobile">
            {(currentUser?.firstName && `Hi ${currentUser.firstName}!`) || 'Account'}
          </span>
        }
      />
    </div>
  );
};

export default AccountMenu;
