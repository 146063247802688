import * as React from 'react';
import cn from 'classnames';
import { Taxon } from 'contexts/AppContext/types';
import { PopoverButton } from '@headlessui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { clickGraphicDesignLink } from 'api/gtm';
import { isProductPage } from 'libs/utils/pageUtils';
import AppContext from 'contexts/AppContext/AppContext';

interface Props {
  name: string;
  items: Taxon[];
  categories?: string[];
  onItemClick: () => void;
}

const TilePanel = ({ name, items, categories = null, onItemClick }: Props) => {
  const location = useLocation();
  const appContext = React.useContext(AppContext);

  const data = [
    {
      name: 'Rush Critical Overnight Printing',
      tagline: 'Get your prints within 24 hours. Next day and same day printing services on most products.',
      action: 'Get A Quote',
      link: '/rush-critical',
    },
    // {
    //   name: 'Free Custom Box Design',
    //   tagline:
    //     'Order 100 shipping boxes and get a free custom design! Start marketing and branding your shipments today!',
    //   action: 'Contact Our Designers',
    //   link: '/box-design',
    // },
    {
      name: 'Franchise Procurement Solutions',
      tagline:
        'Create print and promo with accurate and consistent branding. Manage assets, run reporting, and unify spend via custom portals.',
      action: 'Learn More',
      link: '/franchise-solutions',
    },
    {
      name: 'Graphic Design Services',
      tagline:
        'Let Printivity create a custom design that will take your project to the next level, with standard and rush options.',
      action: 'Get a Quote',
      link: '/design-services',
    },
    {
      name: 'Printivity Enterprise Solutions',
      tagline:
        'Transform your business operations with Printivity Cloud, a gateway for enterprises looking for a modern end-to-end workflow and logistics solution.',
      action: 'Learn More',
      link: '/cloud-enterprise-services',
    },
  ];

  const getGridClass = (arr: Taxon[]): string => {
    let retVal: string;
    if (arr.length === 3) {
      retVal = 'md:grid-cols-3';
    } else if (arr.length === 4) {
      retVal = 'md:grid-cols-4';
    } else {
      retVal = 'md:grid-cols-5';
    }
    return retVal;
  };

  return (
    <div className="my-2 flex w-full flex-col items-center space-y-2">
      <div
        className={cn(getGridClass(items), 'grid w-full max-w-6xl list-none gap-x-4 gap-y-8 p-0 xl:gap-x-6')}
      >
        {items.map((file: Taxon) => (
          <div key={file.name} className="relative">
            <div className="group block w-full p-2 transition duration-300 ease-in-out hover:shadow-md ">
              <PopoverButton
                as={RouterLink}
                to={
                  data.find(elem => elem.name === file.name) !== undefined
                    ? data.find(elem => elem.name === file.name).link
                    : file.pagePath
                }
                className="cursor-pointer text-default no-underline transition duration-300
                   ease-in-out group-hover:text-gray-300"
                onClick={() => {
                  if (
                    file.name === 'Graphic Design Services' &&
                    isProductPage(location.pathname, appContext.store.cmsPages)
                  ) {
                    clickGraphicDesignLink({ location: 'pdp_navbar' });
                  }
                  onItemClick();
                }}
              >
                <div>
                  <div className="overflow-hidden rounded-lg bg-gray-100">
                    <img
                      src={file.image.url}
                      alt={file.name}
                      className="pointer-events-none aspect-[10/7] w-full transform object-cover transition duration-300 ease-in-out group-hover:scale-110 group-hover:opacity-80"
                    />
                  </div>
                  <div
                    className={cn(
                      items.length > 4 ? 'text-center' : 'text-left',
                      'mt-2 block font-hvBold text-sm text-default transition duration-300 ease-in-out group-hover:text-gray-300',
                    )}
                  >
                    {file.name}
                  </div>
                  {name === 'Services' && (
                    <>
                      <div className="group mt-2 block text-left text-sm text-gray-300 text-wrap">
                        {data.find(elem => elem.name === file.name)?.tagline}
                      </div>
                      <div className="mt-2 block text-left font-hvBold text-sm text-blue group-hover:underline text-wrap">
                        {data.find(elem => elem.name === file.name)?.action}
                      </div>
                    </>
                  )}
                </div>
              </PopoverButton>
            </div>
          </div>
        ))}
      </div>
      {items.length >= 6 && !!categories && (
        <div className="grid grid-flow-col auto-cols-fr w-full max-w-6xl list-none gap-x-4 gap-y-8 p-0 xl:gap-x-8 border-t border-solid border-gray-300 pb-2 pt-4 text-center">
          {categories.map((category: string) => (
            <PopoverButton
              as={RouterLink}
              to={`/${category.toLowerCase()}`}
              className="cursor-pointer font-hvBold text-default no-underline hover:text-gray-300"
              onClick={onItemClick}
            >
              Shop All {category}
            </PopoverButton>
          ))}
        </div>
      )}
    </div>
  );
};

export default TilePanel;
