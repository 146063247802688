import * as React from 'react';
import { Taxon } from 'contexts/AppContext/types';
import { PopoverButton } from '@headlessui/react';
import { Link as RouterLink } from 'react-router-dom';

export interface HighlightPanelProps {
  item: Taxon;
  title: string;
  subtext: string;
}

interface Props {
  items: HighlightPanelProps[];
  onItemClick: () => void;
}

const HighlightPanel = ({ items, onItemClick }: Props) => (
  <>
    {items.map((item: HighlightPanelProps, i: number) => (
      <div key={`${item.item.name}-${i}`} className="flex w-full flex-row justify-center">
        <div className="m-0 flex w-1/3 justify-center p-0">
          <PopoverButton
            as={RouterLink}
            to={item.item.pagePath}
            className="cursor-pointer text-gray-300 no-underline hover:text-default"
            onClick={onItemClick}
          >
            <img className="objects-contain h-56 w-56 rounded-lg" src={item.item.image.url} alt="item" />
          </PopoverButton>
        </div>
        <PopoverButton
          as={RouterLink}
          to={item.item.pagePath}
          className="my-auto grow cursor-pointer text-gray-300 no-underline hover:text-default"
        >
          <div className=" mx-6 bg-gray-600 py-4 text-center ">
            <div className="text-3xl font-bold text-shades-0">{item.title}</div>
            <p className="mt-2 text-xl text-shades-0">{item.subtext}</p>
          </div>
        </PopoverButton>
      </div>
    ))}
  </>
);

export default HighlightPanel;
